"use client"

import { PostHogProvider } from "posthog-js/react"
import { posthogService } from "@recall/common"

if (typeof window !== "undefined" && process.env.NODE_ENV !== "development") {
    posthogService.init({
        autocapture: false,
        capture_pageview: true,
        disable_session_recording: true,
    })
}

export function PHProvider({ children }: { children: React.ReactNode }) {
    // @ts-ignore
    return <PostHogProvider client={posthogService.getClient()}>{children}</PostHogProvider>
}
