import { Box, SxProps, Theme } from "@mui/material"
import { FC } from "react"

interface Props {
    sx?: SxProps<Theme>
}

export const GlowDivider: FC<Props> = ({ sx = {} }) => {
    const style = { ...styles.divider, ...sx }
    return <Box sx={style} />
}

const styles: Record<string, SxProps<Theme>> = {
    divider: {
        height: "1px",
        width: "88%",
        mt: 4,
        background:
            "linear-gradient(90deg, rgba(166, 171, 218, 0.06) 0%, rgba(166, 171, 218, 0.30) 50.67%, rgba(32, 32, 32, 0.30) 100%)",
    },
}
