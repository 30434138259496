import { useTabFocus } from "@recall/common"
import { UserCookieData, websiteCookieService } from "@recall/common"
import { useEffect, useState } from "react"

export const useUserCookie = () => {
    const [userData, setUserData] = useState<UserCookieData | null>(null)

    const getUserData = () => {
        const cookieData = websiteCookieService.get()
        if (JSON.stringify(cookieData) !== JSON.stringify(userData)) {
            setUserData(cookieData)
        }
        return userData
    }

    useTabFocus(getUserData)

    useEffect(() => {
        getUserData()
    }, [])

    return userData
}
