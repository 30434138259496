"use client"
import CloseIcon from "@mui/icons-material/Close" // Importing the close icon
import { Box, IconButton, Theme, SxProps, Typography, Button, alpha, Fade } from "@mui/material"
import { useState } from "react"
import Image from "next/image"
import { usePathname } from "next/navigation" // Import the usePathname hook
import { PRODUCT_HUNT_DISCOUNT_URL } from "@/constants/routes"
import animations from "../utility/animations"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

export const WebsiteNotice = () => {
    const [isExpanded, setIsExpanded] = useState(true)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

    const pathname = usePathname()
    if (pathname !== "/") return null

    const endDate = "2025-05-01"
    const handleClose = (_: React.MouseEvent) => {
        setIsExpanded(false)
    }

    const difference = +new Date(endDate) - +new Date()
    const isNoticeEnded = difference < 0

    if (isNoticeEnded) return null

    return (
        <Fade in={isExpanded} timeout={{ enter: 1000, exit: 500 }} unmountOnExit>
            <Box
                id="featured-popover"
                sx={{
                    ...styles.container,
                    ...(isMobile && {
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        transform: "translate(-50%, -50%)",
                    }),
                }}
            >
                <Box sx={styles.content}>
                    <IconButton onClick={handleClose} aria-label="close" sx={styles.closeButton}>
                        <CloseIcon />
                    </IconButton>
                    <Image
                        src="/svgs/recall_icon.svg"
                        alt="recall-logo"
                        width={isMobile ? 35 : 49}
                        height={isMobile ? 35 : 50}
                        priority
                    />
                    <Box sx={styles.liveNotification}>
                        <Box position="relative">
                            <Box className="blue-dot" />
                            <Box className="pulse-ring" />
                        </Box>
                        <Typography className="text">Live on Product Hunt</Typography>
                    </Box>
                    <Typography
                        variant="h5"
                        fontWeight={500}
                        sx={isMobile ? { fontSize: "1.2rem" } : {}}
                    >
                        Augmented Browsing
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: (theme) => alpha(theme.palette.text.primary, 0.9),
                            fontWeight: 400,
                            ...(isMobile ? { fontSize: "0.9rem" } : {}),
                        }}
                    >
                        Never forget again
                    </Typography>
                    <Button
                        sx={styles.notifyButton}
                        href={PRODUCT_HUNT_DISCOUNT_URL}
                        target="_blank"
                        rel="nofollow"
                        variant="contained"
                        fullWidth
                    >
                        Get 25% off Recall
                    </Button>
                </Box>
            </Box>
        </Fade>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        background: (theme) => theme.palette.background.layout,
        position: "fixed",
        right: 10,
        bottom: 10,
        zIndex: 200,
        maxWidth: 280,
        textAlign: "center",
        borderRadius: 1,
        overflow: "hidden",
        border: (theme) => `2px solid ${alpha(theme.palette.primary.main, 0.5)}`,
        boxShadow: 1,
    },
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        pt: { xs: 2, md: 4 },
        pb: 2,
        px: 3,
        background: (theme) =>
            `linear-gradient(to bottom, ${alpha(theme.palette.primary.main, 0.25)} 0%, ${alpha(theme.palette.primary.main, 0.15)} 100%)`,
    },
    closeButton: {
        position: "absolute",
        top: 4,
        right: 4,
        color: "text.primary",
    },
    notifyButton: {
        lineHeight: 1.5,
        color: "text.primary",
        py: 1,
        fontSize: 16,
        borderRadius: 0.5,
        fontWeight: 600,
        mt: 1,
    },
    liveNotification: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 210,
        // width: 180,
        height: 35,
        borderRadius: 17,
        backgroundColor: (theme) => theme.palette.background.default,
        border: "1px solid #23294B",
        gap: 1.5,
        "& .blue-dot": {
            width: 8,
            height: 8,
            backgroundColor: (theme) => theme.palette.success.main,
            borderRadius: "50%",
        },
        "& .pulse-ring": {
            border: (theme) => `2px solid ${theme.palette.success.main}`,
            borderRadius: "50%",
            width: 20,
            height: 20,
            opacity: 1,
            animation: animations.pulseRing,
            position: "absolute",
            left: -6,
            top: -6,
        },
        "& .text": {
            fontSize: 14,
            color: (theme) => theme.palette.common.white,
            fontWeight: 500,
        },
    },
}
