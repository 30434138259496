"use client"
import {
    APP_STORE_URL,
    CHROME_EXTENSION_STORE_URL,
    DISCORD_URL,
    FIREFOX_RECALL_EXTENSION_STORE_URL,
    LINKEDIN_URL,
    PLAY_STORE_URL,
    ROUTES,
    TWITTER_URL,
    YOUTUBE_URL,
} from "@/constants/routes"
import { Box, Container, Grid, SxProps, Theme, Typography, alpha } from "@mui/material"
import Image from "next/image"
import Link from "next/link"
import { CSSProperties } from "react"
import { GlowDivider } from "../GlowDivider"
import { PlainLink } from "../links/PlainLink"
import SlideUpAnimation from "../utility/SlideUpAnimation"
import { Logo } from "./Logo"

const footerColumns = [
    {
        title: "Company",
        links: [
            { label: "Privacy", href: ROUTES.PRIVACY_POLICY },
            { label: "Terms of Service", href: ROUTES.TERMS_OF_SERVICE },
            { label: "Fair Use Policy", href: ROUTES.FAIR_USE_POLICY },
        ],
    },
    {
        title: "Product",
        links: [
            { label: "Pricing", href: ROUTES.PRICING },
            { label: "Blog", href: ROUTES.BLOG },
            { label: "Summaries", href: ROUTES.SUMMARIES },
            { label: "Docs", href: ROUTES.DOCS, external: true },
        ],
    },
    {
        title: "Feedback",
        links: [
            { label: "FAQ", href: ROUTES.FAQ },
            { label: "Feature Request", href: ROUTES.FEATURE_REQUEST, external: true },
            { label: "Change Log", href: ROUTES.CHANGE_LOG, external: true },
            { label: "Roadmap", href: ROUTES.ROADMAP, external: true },
        ],
    },
    {
        title: "Contact",
        links: [
            { label: "Twitter", href: TWITTER_URL, external: true },
            { label: "Discord", href: DISCORD_URL, external: true },
            { label: "Youtube", href: YOUTUBE_URL, external: true },
            { label: "LinkedIn", href: LINKEDIN_URL, external: true },
        ],
    },
    {
        title: "Download",
        links: [
            { label: "Chrome", href: CHROME_EXTENSION_STORE_URL, external: true },
            { label: "FireFox", href: FIREFOX_RECALL_EXTENSION_STORE_URL, external: true },
            { label: "App Store", href: APP_STORE_URL, external: true },
            { label: "Google Play", href: PLAY_STORE_URL, external: true },
        ],
    },
]

export const Footer = () => {
    return (
        <Container sx={{ width: "100%" }}>
            <SlideUpAnimation>
                <Box sx={styles.divider}>
                    <GlowDivider />
                </Box>
                <Box sx={styles.footerContainer} component="footer">
                    <Box>
                        <Link href={ROUTES.HOME}>
                            <Logo size="sm" />
                        </Link>
                        <Link
                            href="https://www.producthunt.com/posts/active-recall?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-active&#0045;recall"
                            target="_blank"
                            style={styles.logoLink as CSSProperties}
                        >
                            <Image
                                src="/svgs/product_hunt_product_of_the_month.svg"
                                alt="Recall Browser Extension on Product Hunt"
                                width={211}
                                height={44}
                            />
                        </Link>
                    </Box>
                    <Box flex={1}>
                        <Grid container spacing={1} rowSpacing={4} columnSpacing={2}>
                            <Grid
                                item
                                sx={{ display: { xs: "none", lg: "block" } }}
                                xs={0}
                                lg={2}
                            />
                            {footerColumns.map((column) => (
                                <Grid key={column.title} item xs={4} md={3} lg={2}>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        sx={styles.columnTitle}
                                    >
                                        {column.title}
                                    </Typography>
                                    {column.links.map((link) => (
                                        <PlainLink
                                            key={link.label}
                                            sx={styles.link}
                                            href={link.href}
                                            aria-label={`${link.label} link button`}
                                            target={link.external ? "_blank" : undefined}
                                        >
                                            <Box>
                                                {link.label}{" "}
                                                {["App Store", "Google Play"].includes(
                                                    link.label
                                                ) && (
                                                    <Box component="span" sx={{ fontSize: "10px" }}>
                                                        in βeta
                                                    </Box>
                                                )}
                                            </Box>
                                        </PlainLink>
                                    ))}
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </SlideUpAnimation>
        </Container>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    divider: {
        display: "flex",
        justifyContent: "center",
        mb: 6,
    },

    footerContainer: {
        display: "flex",
        gap: 6,
        flexDirection: { xs: "column", lg: "row" },
        justifyContent: { lg: "space-between" },
    },

    link: {
        py: 1,
        pr: 0,
        minWidth: 48,
        color: (theme) => alpha(theme.palette.text.primary, 0.8),
        fontWeight: 500,
        fontSize: "13px",
    },
    columnTitle: {
        color: "text.primary",
        pb: 1.25,
        fontWeight: 600,
        fontFamily: "secondaryFontFamily",
        fontSize: "16px",
    },
    externalLinks: {
        gap: 0,
        flexWrap: "wrap",
    },
    logoLink: {
        height: 44,
        display: "block",
        marginTop: "30px",
        width: "fit-content",
    },
}
