import { Box, BoxProps } from "@mui/material"
import Image from "next/image"

interface Props extends BoxProps {
    size: "sm" | "md" | "lg"
}

const sizes = {
    sm: { height: 36, width: 110 },
    md: { height: 60, width: 164 },
    lg: { height: 70, width: 191 },
}

export const Logo = ({ size, ...props }: Props) => {
    return (
        <Box display="flex" {...props}>
            <Image {...sizes[size]} alt="Recall logo" src="/svgs/logo.svg" priority />
        </Box>
    )
}
