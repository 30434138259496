import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/website/node_modules/next/font/google/target.css?{\"path\":\"src/theme/fonts.ts\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"primaryFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["GlowNav"] */ "/vercel/path0/packages/website/src/app/components/GlowNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/vercel/path0/packages/website/src/app/components/layout/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navigation"] */ "/vercel/path0/packages/website/src/app/components/layout/Navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebsiteNotice"] */ "/vercel/path0/packages/website/src/app/components/promos/WebsiteNotice.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReferralCookie"] */ "/vercel/path0/packages/website/src/app/components/ReferralCookie.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GTMScript"] */ "/vercel/path0/packages/website/src/app/components/scripts/GTMScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/vercel/path0/packages/website/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SnackbarProvider"] */ "/vercel/path0/packages/website/src/app/store/SnackbarProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/website/src/theme/ThemeRegistry.tsx");
