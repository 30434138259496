import { useUserCookie } from "@/app/hooks/useUserCookie"
import { ROUTES } from "@/constants/routes"
import { Box, Fade, SxProps, Theme } from "@mui/material"
import { CustomButton } from "./CustomButton"
import { LinkButton } from "./LinkButton"

interface Props {
    sx?: SxProps<Theme>
}

export const OpenAppButtons = ({ sx }: Props) => {
    const user = useUserCookie()
    const isLoggedIn = Boolean(user?.uid)

    return (
        <Fade in timeout={1300}>
            <Box sx={sx}>
                {!isLoggedIn && (
                    <LinkButton href={ROUTES.LOGIN} aria-label="Login link button">
                        Sign in
                    </LinkButton>
                )}
                <CustomButton href={ROUTES.SIGNUP}>
                    {isLoggedIn ? "Open App" : "Get Started"}
                </CustomButton>
            </Box>
        </Fade>
    )
}
