"use client"
import { useIsScrolled } from "@/app/hooks/useIsScrolled"
import { ROUTES } from "@/constants/routes"
import { alpha, AppBar, Box, Container, SxProps, Theme, Toolbar } from "@mui/material"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { LinkButton } from "../buttons/LinkButton"
import { OpenAppButtons } from "../buttons/OpenAppButtons"
import { Logo } from "./Logo"
import { MobileMenu } from "./MobileMenu"
import { pages } from "./pages"

export const Navigation = () => {
    const pathname = usePathname()
    const isScrolled = useIsScrolled()

    const navContainerStyle = {
        ...styles.navContainer,
        ...(isScrolled ? styles.navContainerScrolled : {}),
    }

    return (
        <AppBar position="sticky" elevation={0} sx={styles.appBar}>
            <Container sx={navContainerStyle}>
                <Toolbar sx={styles.toolbar}>
                    <Box sx={styles.leftSection}>
                        <Link style={{ paddingRight: 16 }} href={ROUTES.HOME}>
                            <Logo size="sm" />
                        </Link>
                        <Box sx={styles.desktopNav}>
                            {pages.map((page) => (
                                <LinkButton
                                    sx={{
                                        ...styles.navLink,
                                        fontWeight: pathname === page.url ? 600 : 500,
                                    }}
                                    aria-label={`${page.title} link button`}
                                    key={page.url}
                                    href={page.url}
                                    target={page?.target}
                                >
                                    {page.title}
                                </LinkButton>
                            ))}
                        </Box>
                    </Box>

                    <Box sx={styles.desktopButtons}>
                        <OpenAppButtons sx={styles.openAppButtons} />
                    </Box>

                    <Box sx={styles.mobileMenu}>
                        <MobileMenu />
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    appBar: {
        backgroundColor: "transparent",
        px: { sx: "unset", md: 2 },
    },
    navContainer: {
        width: "100%",
        webkitBackdropFilter: "blur(15px)",
        transition: "all 0.3s ease",
        border: "1px solid transparent",
        borderRadius: { xs: "unset", md: "0 0 8px 8px" },
        px: 2,
    },
    navContainerScrolled: {
        backgroundColor: "transparent",
        backdropFilter: "blur(15px)",
        border: (theme) => `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    },
    toolbar: {
        px: { xs: "0 !important", md: "0 !important", lg: "0 !important" },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        py: 2.5,
    },
    leftSection: {
        display: "flex",
    },
    desktopNav: {
        display: { xs: "none", md: "flex" },
    },
    navLink: {
        color: "text.primary",
        fontWeight: 500,
        fontSize: "0.9rem",
    },
    desktopButtons: {
        display: { xs: "none", md: "block" },
    },
    openAppButtons: {
        display: "flex",
        gap: 2,
        alignItems: "center",
        justifyContent: "center",
    },
    mobileMenu: {
        display: { xs: "block", md: "none" },
    },
}
