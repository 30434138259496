"use client"

import { Alert, Snackbar, alpha, SxProps, Theme, AlertColor } from "@mui/material"
import { createContext, FC, ReactNode, useContext, useState } from "react"

interface SnackbarContextType {
    showMessage: (message: string, severity: AlertColor) => void
    closeSnackbar: () => void
}

const SnackbarContext = createContext<SnackbarContextType>({
    showMessage: () => {},
    closeSnackbar: () => {},
})

export const useSnackbar = () => useContext(SnackbarContext)

interface SnackbarState {
    open: boolean
    message: string | null
    severity: AlertColor | null
}

export const SnackbarProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [snackbar, setSnackbar] = useState<SnackbarState>({
        open: false,
        message: null,
        severity: null,
    })

    const showMessage = (message: string, severity: AlertColor) => {
        setSnackbar({ open: true, message, severity })
    }

    const closeSnackbar = () => {
        setSnackbar((prev) => ({ ...prev, open: false }))
    }

    return (
        <SnackbarContext.Provider value={{ showMessage, closeSnackbar }}>
            {children}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={closeSnackbar}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={closeSnackbar}
                    severity={snackbar.severity || "success"}
                    sx={styles.snackbar}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    snackbar: {
        border: (theme) => `1px solid ${alpha(theme.palette.text.primary, 0.15)}`,
    },
}
