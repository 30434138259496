"use client"

import { Close, Menu } from "@mui/icons-material"
import { Box, Grow, IconButton, Modal, Paper, SxProps, Theme } from "@mui/material"
import { useState } from "react"
import { LinkButton } from "../buttons/LinkButton"
import { OpenAppButtons } from "../buttons/OpenAppButtons"
import { Logo } from "./Logo"
import { pages } from "./pages"

export const MobileMenu = () => {
    const [isOpen, setIsOpen] = useState(false)

    const handleOpen = () => {
        setIsOpen(true)
    }

    const handleClose = () => {
        setIsOpen(false)
    }

    return (
        <>
            <IconButton aria-label="Menu button" onClick={handleOpen}>
                <Menu sx={{ fill: "white" }} />
            </IconButton>
            <Modal open={isOpen} onClose={handleClose}>
                <Box>
                    <Grow in timeout={250}>
                        <Paper sx={styles.menu}>
                            <IconButton
                                aria-label="Close menu button"
                                sx={styles.close}
                                onClick={handleClose}
                            >
                                <Close fontSize="large" />
                            </IconButton>
                            <Logo size="lg" sx={{ mb: 2 }} />
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                sx={styles.links}
                            >
                                {pages.map((page) => (
                                    <LinkButton
                                        sx={styles.link}
                                        key={page.url}
                                        href={page.url}
                                        onClick={handleClose}
                                        aria-label={`${page.title} link button`}
                                    >
                                        {page.title}
                                    </LinkButton>
                                ))}
                            </Box>
                            <Box sx={styles.cta}>
                                <OpenAppButtons sx={styles.openAppButtons} />
                            </Box>
                        </Paper>
                    </Grow>
                </Box>
            </Modal>
        </>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    menu: {
        height: "100vh",
        width: "100vw",
        top: 0,
        left: 0,
        gap: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: (theme) => theme.palette.background.default,
        borderRadius: "0 !important",
    },

    close: {
        position: "absolute",
        right: 20,
        top: 20,
        color: "white",
    },
    link: {
        px: 2,
        fontSize: (theme) => theme.typography.body1.fontSize,
    },
    cta: {
        mt: 3,
        display: "flex",
        gap: 2,
        flexDirection: "column",
    },
    openAppButtons: {
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
        justifyContent: "center",
    },
}
