"use client"
import { Theme, alpha } from "@mui/material/styles"
import { Box, SxProps } from "@mui/material"
import Image from "next/image"

export const GlowNav = () => {
    return (
        <Box
            sx={styles.navGlow}
        >
            <Image
                fill
                src="/svgs/backgrounds/background-glow-nav.svg"
                alt="Navigation background glow"
            />
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    navGlow: {
        position: "absolute",
        top: { xs: 0, sm: -10, md: -20 },
        left: { xs: "-30%", sm: "-5%" },
        opacity: 0.8,
        width: "100vw",
        aspectRatio: { xs: 0.7, lg: 1 },
    },
}